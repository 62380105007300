import { FilmIcon } from '@heroicons/react/24/outline'
import { memo } from 'react'
import { useSelector } from 'react-redux'

import ControlsPlayback from './ControlsPlayback'

export const ControlsButtonBar = memo((props) => {
  const { swiper, syncPlayTime } = props

  const isTouched = useSelector((state) => state.subtitles.touched)
  const iconClassname = 'w-12 h-12 lg:w-16 lg:h-16 select-none touch-none'

  return (
    <div className="flex flex-row justify-between text-neutral-700 mt-4 h-14 lg:h-16">
      <div className="flex flex-row items-center">
        <FilmIcon className={`${iconClassname} invisible`} />
        {/* <MinusCircleIcon className={`${iconClassname}`}/>
          <PlusCircleIcon className={`${iconClassname}`}/> */}
      </div>

      <div className="flex flex-row items-center">
        <ControlsPlayback swiper={swiper} syncPlayTime={syncPlayTime} iconClassname={iconClassname} />
      </div>

      <div className="flex flex-row justify-center content-center min-w-14 min-h-14">
        <FilmIcon className={`w-6 rotate-90 hidden ${isTouched ? '' : 'invisible'}`} />
      </div>
    </div>
  )
})
ControlsButtonBar.displayName = 'ControlsButtonBar'
export default ControlsButtonBar
