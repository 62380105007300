import { useSpring, animated } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { useRef, useState, useEffect, memo, useMemo, useCallback } from 'react'

const MAX_TRAVEL_RATIO = 1.5

const DragX = memo((props) => {
  const wrapperRef = useRef(null)
  const [travelLimit, setTravelLimit] = useState(0)

  useEffect(() => {
    if (!wrapperRef.current) {
      return
    }
    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    setTravelLimit(wrapperRect.width * MAX_TRAVEL_RATIO)
  }, [wrapperRef])

  const { onDragStart, onDragEnd, onDrag, directionSign } = props
  const [dragTimer, setDragTimer] = useState(null)
  const handleDragStart = useCallback(() => {
    // console.log("onDragStart");
    setDragTimer(
      setTimeout(() => {
        onDragStart && onDragStart()
        // setDragTimer(null)
      }, 200),
    )
  }, [onDragStart])
  const handleDragEnd = useCallback(() => {
    if (dragTimer) {
      clearTimeout(dragTimer)
      setDragTimer(null)
    }
    // console.log("onDragEnd");
    onDragEnd && onDragEnd()
  }, [onDragEnd, dragTimer])

  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }))
  const handleDrag = useCallback(
    ({ down, movement: [mx] }) => {
      if (!travelLimit) {
        return
      }
      const negativeTravel = -1 * directionSign * mx
      if (negativeTravel > 0) {
        mx = 0
      } else if (Math.abs(mx) >= travelLimit) {
        mx = travelLimit * directionSign
      }
      const scrubbingSpeed = 1 + (Math.abs(mx) / travelLimit) * 2
      onDrag && onDrag(scrubbingSpeed)

      // console.log("onDrag", scrubbingSpeed);
      api.start({ x: down ? mx : 0, y: 0, immediate: down })
    },
    [onDrag, directionSign, travelLimit, api],
  )

  const bind = useGesture({
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd,
    onDrag: handleDrag,
  })
  const animatedStyle = useMemo(() => ({ x, y }), [x, y])

  return (
    <animated.div {...bind()} ref={wrapperRef} style={animatedStyle} className="touch-none">
      {props.children}
    </animated.div>
  )
})

export const DragLeft = memo((props) => {
  return <DragX directionSign={-1} {...props} />
})
DragLeft.displayName = 'DragLeft'

export const DragRight = memo((props) => {
  return <DragX directionSign={+1} {...props} />
})
DragRight.displayName = 'DragRight'
