import { memo } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export const CloseButton = memo((props) => {
  const baseClassName = 'w-10 h-10 absolute top-2 right-2 inline-flex z-10 cursor-pointer'
  const className = `${baseClassName} ${props.className || ''}`
  return (
    <div onClick={props.onClick} className={className}>
      <XMarkIcon className="text-neutral-800 z-5" />
    </div>
  )
})

CloseButton.displayName = 'CloseButton'
export default CloseButton
