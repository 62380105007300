import { useCallback, memo, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'

import { setSeeking } from './subtitleSlice'

import './slider-single.css'

const THUMBS_DISABLED = [true, false]

export const ControlsRangeSlider = memo(({ swiper, playTime }) => {
  const dispatch = useDispatch()

  const subtitles = useSelector((state) => state.subtitles.subtitleData)
  const maxPlayTime = useSelector((state) => state.subtitles.maxPlayTime)

  // subtitle snapping
  const slideToSubtitle = useCallback(
    (sliderRangePair) => {
      const selectedPlayTime = Math.max(0, Math.min(maxPlayTime, sliderRangePair[1]))
      const correspondingSubtitleIndex = subtitles.findIndex((s) => s.endTime >= selectedPlayTime)
      const correspondingSlideIndex = correspondingSubtitleIndex < 0 ? 0 : (correspondingSubtitleIndex - 1)
      swiper.slideTo(correspondingSlideIndex, 0)
    },
    [swiper, subtitles, maxPlayTime],
  )

  const onDragStart = useCallback(() => {
    dispatch(setSeeking(true))
  }, [dispatch])
  const onDragEnd = useCallback(() => {
    dispatch(setSeeking(false))
  }, [dispatch])

  const sliderValue = useMemo(() => ([0, playTime]), [playTime, ])

  return (
    <RangeSlider
      className="single-thumb"
      onInput={slideToSubtitle}
      onThumbDragStart={onDragStart}
      onRangeDragStart={onDragStart}
      onRangeDragEnd={onDragEnd}
      onThumbDragEnd={onDragEnd}
      value={sliderValue}
      min="0"
      step="500"
      max={maxPlayTime}
      thumbsDisabled={THUMBS_DISABLED}
      rangeSlideDisabled={true}
    />
  )
})
ControlsRangeSlider.displayName = 'ControlsRangeSlider'
export default ControlsRangeSlider