import { PlayIcon } from "@heroicons/react/24/outline"
import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useWakeLock } from 'react-screen-wake-lock'

import CloseButton from "./CloseButton"
import { startPlaying, exitWaitingRoom } from "./subtitleSlice"

export default function SubtitlePlayPage() {
  const dispatch = useDispatch()

  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.log('Screen Wake Lock: requested!'),
    onError: () => alert('Screen Wake Lock failed'),
    onRelease: () => console.log('Screen Wake Lock: released!'),
  })

  const handleCloseClick = useCallback(() => {
    dispatch(exitWaitingRoom())
  }, [dispatch])

  const handleClick = useCallback(() => {
    if (isSupported) {
      request()
    }
    dispatch(startPlaying())
  }, [dispatch, isSupported, request])

  useEffect(() => {
    return () => (released === false ? release() : null)
  }, [release, released])

  return (
    <div className="flex flex-col justify-between align-center px-4" style={{ height: '100dvh' }}>
      <CloseButton onClick={handleCloseClick} />

      <div className="pt-10" />

      <div className="flex flex-row justify-center align-center">

        <div className="bg-transparent text-neutral-300 font-bold py-6 px-6 rounded inline-flex items-center h-12 cursor-pointer" onClick={handleClick}>
          <PlayIcon className="h-12 w-12"/>
          <span className="align-center text-5xl">Start</span>
        </div>

      </div>
      <div className="flex flex-row justify-center align-center pb-10">
        <p>Wake API is {isSupported ? '' : 'not'} supported</p>
      </div>
    </div>
  )
}
