import { useSelector } from 'react-redux'
import classNames from 'classnames'

export default function SubtitleSlide({ index, value: subtitle }) {
  const seeking = useSelector((state) => state.subtitles.seeking)
  const touched = useSelector((state) => state.subtitles.touched)
  const visible = useSelector((state) => state.subtitles.visible)
  const activeSlideIndex = useSelector((state) => state.subtitles.slideIndex)
  const isNext = index === activeSlideIndex + 1
  const invisible = isNext ? !visible && !touched && !seeking : !touched && !seeking

  // todo: do not reveal on scroll
  const slideStyle = classNames(
    [
      'flex',
      'flex-col',
      'grow',
      'justify-center',
      'items-center',
      'text-center',
      'text-2xl',
      'font-bold',
      'bg-black',
      'w-full',
      'h-full',
    ],
    {
      invisible,
    },
  )
  return <div className={slideStyle}>{subtitle.text}</div>
}
