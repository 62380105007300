import Picker from 'app/features/file/Picker'

export default function App() {

  return (
    <div className="flex flex-col justify-center align-center px-4" style={{ height: '100dvh' }}>
      <div className="flex flex-row justify-center align-center">
        <Picker />
      </div>
    </div>
  )
}
