import { memo } from 'react'
import ControlsRangeInfo from './ControlsRangeInfo'
import ControlsRangeSlider from './ControlsRangeSlider'
import ControlsRangeProgress from './ControlsRangeProgress'

export const ControlsRangePlaceholder = memo(() => {
  return (
    <>
      <div className="min-h-10 invisible"></div>
      <div className="invisible" style={{ height: '8px' }}></div>
    </>
  )
})

export const ControlsRange = memo(({ swiper, playTime, progress }) => {
  return (
    <>
      <div className="flex flex-row justify-between align-middle h-10 lg:h-16 text-sm text-neutral-500">
        <ControlsRangeInfo playTime={playTime} />
      </div>

      <div className={`flex flex-col`}>
        {progress ? (
          <ControlsRangeProgress playTime={playTime} />
        ) : (
          <ControlsRangeSlider playTime={playTime} swiper={swiper} />
        )}
      </div>
    </>
  )
})
ControlsRange.displayName = 'ControlsRange'
export default ControlsRange
