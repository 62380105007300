import { useEffect } from 'react'
import { useSwiper } from 'swiper/react'

export default function SubtitleSwipeProvider({ onChange }) {
  const swiper = useSwiper()

  useEffect(() => {
    onChange(swiper)
  }, [swiper, onChange])

  return null
}
