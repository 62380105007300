import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'

import './slider-single.css'

function calc(playTime, startTime, endTime) {
  const duration = endTime - startTime
  let elapsed = playTime - startTime
  if (elapsed < 0) {
    elapsed = 0
  }
  return (elapsed / duration) * 100
}

export const ControlsRangeProgress = memo(({ playTime }) => {
  const subtitles = useSelector((state) => state.subtitles.subtitleData)
  const subtitleIndex = useSelector((state) => state.subtitles.subtitleIndex)

  let progress = 0
  const subtitle = subtitles[subtitleIndex]
  const nextSubtitle = subtitles[subtitleIndex + 1]
  if (subtitle) {
    if (subtitle && playTime >= subtitle.startTime && playTime <= subtitle.endTime) {
      progress = calc(playTime, subtitle.startTime, subtitle.endTime)
    } else if (subtitle && nextSubtitle) {
      progress = calc(playTime, subtitle.endTime, nextSubtitle.startTime)
    }
  } else if (nextSubtitle) {
    progress = calc(playTime, 0, nextSubtitle.startTime)
  }

  const style = useMemo(() => ({ left: '0', width: `${progress}%` }), [progress])
  return (
    <div className="slide-progress">
      <div className="slide-progress__range" style={style} />
    </div>
  )
})
ControlsRangeProgress.displayName = 'ControlsRangeProgress'
export default ControlsRangeProgress