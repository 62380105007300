import { createSlice } from '@reduxjs/toolkit'
// import { faker } from '@faker-js/faker';
// const fakeData = [
//   { text: "" },
//   ...Array.from({ length: 1000 }).map((el, index) => ({ text: faker.lorem.sentence() }))
// ];

const update = (state, action) => {
  return { ...state, ...action.payload }
}

export const subSlice = createSlice({
  name: 'subtitle',
  initialState: {
    slideIndex: 0,
    subtitle: null,
    subtitleData: [],
    subtitleIndex: -1,
    scrubbingSpeed: 1,
    scrubbing: false, // pulling fwd/bwd controls
    scrolling: false, // scrolling the slides
    started: false,
    seeking: false,
    touched: false,
    holding: false,
    playing: false,
    visible: false,
    seekZoom: false,
    maxPlayTime: 0,
    fontSize: 4,
  },
  reducers: {
    exitWaitingRoom: (state, action) => {
      state.subtitle = null
      state.subtitleData = []
      state.subtitleIndex = -1
      state.slideIndex = 0
    },
    exitSubtitlePlayer: (state, action) => {
      state.playing = false
      state.scrolling = false
      state.scrubbing = false
      state.seeking = false
      state.started = false
      state.touched = false
      state.visible = false
    },
    setSlideMeta: (state, action) => {
      const { slideIndex, subtitleIndex, subtitle, visible } = action.payload
      state.slideIndex = slideIndex
      state.subtitleIndex = subtitleIndex
      state.subtitle = subtitle
      state.visible = visible
    },
    setScrubbingSpeed: (state, action) => {
      state.scrubbingSpeed = action.payload
    },
    setScrubbing: (state, action) => {
      state.scrubbing = action.payload
      state.scrubbingSpeed = 1
    },
    setHolding: (state, action) => {
      state.holding = action.payload
    },
    setScrolling: (state, action) => {
      state.scrolling = action.payload
    },
    setSeeking: (state, action) => {
      state.seeking = action.payload
    },
    setInvisible: (state, action) => {
      state.visible = false
    },
    updatePlaybackTick: update,
    updatePlaybackJump: update,
    updatePlaybackSeek: update,
    loadSubtitles: (state, action) => {
      const subs = action.payload
      state.maxPlayTime = subs[subs.length - 1].endTime
      state.subtitleData = subs
      state.playing = false
      state.started = false
    },
    toggleTouched: (state, action) => {
      state.touched = !state.touched
    },
    setMaxTime: (state, action) => {
      state.maxPlayTime = action.payload
    },
    startPlaying: (state, action) => {
      state.playing = true
      state.started = true
    },
    setPlaying: (state, action) => {
      state.playing = action.payload
    },
    togglePlay: (state) => {
      state.playing = !state.playing
    },
    decFontSize: (state, action) => {
      state.fontSize -= 1
    },
    incFontSize: (state, action) => {
      state.fontSize += 1
    },
  },
})
export const {
  decFontSize,
  exitSubtitlePlayer,
  exitWaitingRoom,
  incFontSize,
  loadSubtitles,
  playerTick,
  setHolding,
  setInvisible,
  setMaxTime,
  setPlaying,
  setScrolling,
  setScrubbing,
  setScrubbingSpeed,
  setSeeking,
  setSeekingTime,
  setSlideMeta,
  startPlaying,
  togglePlay,
  toggleTouched,
  updatePlaybackJump,
  updatePlaybackSeek,
  updatePlaybackTick,
} = subSlice.actions

export default subSlice.reducer
