import { memo } from 'react'
import { useSelector } from 'react-redux'

import { ControlsRange, ControlsRangePlaceholder } from './ControlsRange'
import ControlsButtonBar from './ControlsButtonBar'

export const Controls = memo(({ swiper, playTime, setPlayTime, syncPlayTime }) => {
  const isScrubbing = useSelector((state) => state.subtitles.scrubbing)
  const holding = useSelector((state) => state.subtitles.holding)
  const touched = useSelector((state) => state.subtitles.touched)

  const invisible = !touched && !isScrubbing && !holding
  const displayProgress = isScrubbing || holding;

  // todo: playback as audio in background?
  // todo: click inside controls to show only them
  return (
    <div className="flex flex-col w-full pb-8 px-4">
      {invisible ? (
        <ControlsRangePlaceholder />
      ) : (
        <ControlsRange swiper={swiper} playTime={playTime} setPlayTime={setPlayTime} progress={displayProgress} />
      )}

      {/* <div className="flex flex-col mb-8">
        <ControlsRangeWindow />
      </div> */}

      <ControlsButtonBar swiper={swiper} syncPlayTime={syncPlayTime} />
    </div>
  )
})
Controls.displayName = 'Controls'
export default Controls
