import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fromString } from '../subtitle/tools/srt'
import { loadSubtitles } from '../subtitle/subtitleSlice'

export default function Picker() {
  const dispatch = useDispatch()

  const onChange = useCallback(
    (inputEvent) => {
      const reader = new FileReader()
      reader.onload = (readerEvent) => {
        const subs = fromString(readerEvent.target.result)
        dispatch(loadSubtitles(subs))
      }
      reader.readAsText(inputEvent.target.files[0])
    },
    [dispatch],
  )

  const subtitles = useSelector((state) => state.subtitles.subtitleData)
  if (subtitles.length > 0) {
    return null
  }
  return (
    <section className="">
      <label>
        Pick SRT file
        <br /> <input type="file" id="file" onChange={onChange} />{' '}
      </label>
    </section>
  )
}
