import { useCallback, useEffect, useState } from 'react'
import { useStore } from 'react-redux'

import { setPlaying, setInvisible } from './subtitleSlice'

export function usePlayTime(swiper) {
  const store = useStore()

  const [playTime, setPlayTime] = useState(0)
  const [lastSyncTime, setLastSyncTime] = useState(new Date().getTime())

  const setPlayTimeOutside = useCallback((value) => {
    setPlayTime(value)
    setLastSyncTime(new Date().getTime())
  }, [])

  const syncPlayTime = useCallback(() => {
    setLastSyncTime(new Date().getTime())
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      const state = store.getState().subtitles
      if (!state.subtitleData || state.subtitleData.length <= 0) {
        return
      }
      if (!state.playing || state.seeking || state.scrolling) {
        return
      }
      const { subtitleIndex } = state

      const currentTime = new Date().getTime()
      const passedRealTime = currentTime - lastSyncTime
      const passedTime = passedRealTime * state.scrubbingSpeed
      const currentPlayTime = playTime + passedTime

      const nextSubtitleIndex = subtitleIndex + 1
      const nextSubtitle = state.subtitleData[nextSubtitleIndex]
      const currentSubtitle = state.subtitleData[subtitleIndex]
      if (nextSubtitle && currentPlayTime >= nextSubtitle.startTime) {
        swiper && swiper.slideNext(0)
      } else if (currentSubtitle && currentPlayTime >= currentSubtitle.endTime && state.visible) {
        store.dispatch(setInvisible())
      }
      if (!nextSubtitle) {
        store.dispatch(setPlaying(false))
        // reset to beginning:
        // currentPlayTime = 0
        // swiper.slideTo(0, 0)
      }

      setPlayTime(currentPlayTime)
      setLastSyncTime(currentTime)
    }, 100)
    return () => clearInterval(interval)
  }, [store, swiper, lastSyncTime, playTime])

  // todo: expose floored playTime to lessen rerenders
  return { playTime, setPlayTime: setPlayTimeOutside, syncPlayTime }
}

export default usePlayTime
