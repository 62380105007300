import { useSelector } from 'react-redux'
import SubtitlePage from './app/features/subtitle/SubtitlePage'
import SubtitlePlayPage from './app/features/subtitle/SubtitlePlayPage'
import FilePage from './app/features/file/FilePage'

import './App.css'


export default function App() {
  const subtitleData = useSelector((state) => state.subtitles.subtitleData)
  const started = useSelector((state) => state.subtitles.started)
  if (!subtitleData.length) {
    return <FilePage />
  }
  if (!started) {
    return <SubtitlePlayPage />
  }
  return <SubtitlePage />
}
