import { Swiper, SwiperSlide } from 'swiper/react'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect, useCallback, memo} from 'react'
import { Virtual, Mousewheel } from 'swiper/modules'
import classNames from 'classnames'

import SubtitleSlide from './SubtitleSlide'
import { toggleTouched, setHolding, setScrolling } from './subtitleSlice'
import SubtitleSwipeProvider from './SubtitleSwipeProvider'
import 'swiper/css'
import 'swiper/css/virtual'
import './swiper.css'

const SWIPER_MODULES = [Virtual, Mousewheel]

const SubtitleSwiper = memo(({ onIndexChange, onInstanceChange }) => {
  const dispatch = useDispatch()

  const touched = useSelector((state) => state.subtitles.touched)
  const [touchHoldingStart, setTouchHoldingStart] = useState(0)
  const [touchMoving, setTouchMoving] = useState(null)
  const [touchTimer, setTouchTimer] = useState(null)

  useEffect(() => {
    if (touchMoving === null) {
      return
    }
    dispatch(setScrolling(touchMoving))
  }, [dispatch, touchMoving])

  const onDoubleTap = useCallback(() => {
    if (!document.fullscreenElement && document.body.requestFullscreen) {
      document.body.requestFullscreen()
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, [])

  const onTap = useCallback(() => {
    // console.log('onTap')
    const touchDuration = new Date().getTime() - touchHoldingStart
    if (touchDuration < 300) {
      dispatch(toggleTouched())
    }
  }, [dispatch, touchHoldingStart])

  const onTouchMove = useCallback(() => {
    // console.log('onTouchMove')
    setTouchMoving(true)
  }, [])

  const onTouchStart = useCallback(() => {
    // console.log('onTouchStart')
    setTouchHoldingStart(new Date().getTime())
    setTouchTimer(
      setTimeout(() => {
        dispatch(setHolding(true))
      }, 300),
    )
  }, [dispatch])

  const onTouchEnd = useCallback(() => {
    // console.log('onTouchEnd')
    if (touchMoving) {
      setTouchMoving(false)
    }
    const touchDuration = new Date().getTime() - touchHoldingStart
    if (touchDuration > 300) {
      dispatch(setHolding(false))
    }
    clearTimeout(touchTimer)
    setTouchTimer(null)
  }, [dispatch, touchMoving, touchTimer, touchHoldingStart])

  const subtitles = useSelector((state) => state.subtitles.subtitleData)
  const slideListWrapped = [{ text: '' }, { text: '' }, ...subtitles, { text: '' }, { text: '' }]
  const slideList = slideListWrapped.map((subtitle, index) => (
    <SwiperSlide key={`${index} ${subtitle.text}`} virtualIndex={index}>
      <SubtitleSlide index={index} value={subtitle} />
    </SwiperSlide>
  ))
  if (!subtitles.length) {
    return null
  }

  const swiperStyle = classNames(['text-black', 'w-full', 'h-full'], {
    's-touch': touched,
  })

  return (
    <Swiper
      shortSwipes={true}
      className={swiperStyle}
      modules={SWIPER_MODULES}
      slidesPerView={3}
      direction="vertical"
      onTap={onTap}
      onDoubleTap={onDoubleTap}
      onActiveIndexChange={onIndexChange}
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      virtual
      mousewheel
    >
      <SubtitleSwipeProvider onChange={onInstanceChange} />

      {slideList}

      {/* <span slot="container-start" className='text-white'>Container start</span>
      <span slot="container-end" className='text-white'>Container End</span>
      <span slot="wrapper-start" className='text-white'>wrapper start</span>
      <span slot="wrapper-end" className='text-white'>wrapper End</span> */}
    </Swiper>
  )
})
SubtitleSwiper.displayName = 'SubtitleSwiper'
export default SubtitleSwiper