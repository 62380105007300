import { PlayCircleIcon, PauseCircleIcon, ForwardIcon, BackwardIcon } from '@heroicons/react/24/outline'
import { memo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import store from 'app/store'
import { togglePlay, setScrubbingSpeed, setScrubbing } from './subtitleSlice'
import { DragLeft, DragRight } from './Drag'

export const ControlsPlayback = memo(({ iconClassname, syncPlayTime, swiper }) => {
  const dispatch = useDispatch()

  const jump = (swiper, offset) => {
    if (!swiper) {
      return
    }
    const state = store.getState().subtitles
    const subtitles = state.subtitleData

    const nextSubtitleIndex = Math.min(subtitles.length, Math.max(-1, state.subtitleIndex + offset))
    const nextSlideIndex = nextSubtitleIndex + 1

    swiper.slideTo(nextSlideIndex, 0)
  }

  const handleBwd = useCallback(() => {
    jump(swiper, -1)
  }, [swiper])

  const handleFwd = useCallback(() => {
    jump(swiper, +1)
  }, [swiper])

  const handlePlayPause = useCallback(() => {
    syncPlayTime()
    dispatch(togglePlay())
  }, [dispatch, syncPlayTime])

  const handleScrubbingChangeBwd = useCallback((x) => dispatch(setScrubbingSpeed(1 / x)), [dispatch])
  const handleScrubbingChangeFwd = useCallback((x) => dispatch(setScrubbingSpeed(x)), [dispatch])
  const handleScrubbingStart = useCallback(() => dispatch(setScrubbing(true)), [dispatch])
  const handleScrubbingEnd = useCallback(() => dispatch(setScrubbing(false)), [dispatch])

  const isPlaying = useSelector((state) => state.subtitles.playing)
  const subtitles = useSelector((state) => state.subtitles.subtitleData)

  if (!subtitles.length) {
    return null
  }
  return (
    <>
      <DragLeft onDragStart={handleScrubbingStart} onDragEnd={handleScrubbingEnd} onDrag={handleScrubbingChangeBwd}>
        <BackwardIcon onClick={handleBwd} className={iconClassname} />
      </DragLeft>

      <span className='mx-4'>
        {isPlaying ? (
          <PauseCircleIcon className={iconClassname} onClick={handlePlayPause} />
        ) : (
          <PlayCircleIcon className={iconClassname} onClick={handlePlayPause} />
        )}
      </span>

      <DragRight onDragStart={handleScrubbingStart} onDragEnd={handleScrubbingEnd} onDrag={handleScrubbingChangeFwd}>
        <ForwardIcon onClick={handleFwd} className={iconClassname} />
      </DragRight>
    </>
  )
})
ControlsPlayback.displayName = 'ControlsPlayback'
export default ControlsPlayback