import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import store from 'app/store'
import usePlayTime from './usePlayTime'
import CloseButton from './CloseButton'
import Controls from 'app/features/subtitle/Controls'
import SubtitleSwiper from 'app/features/subtitle/SubtitleSwiper'
import { setSlideMeta, exitSubtitlePlayer } from './subtitleSlice'
import classNames from 'classnames'

export default function SubtitlePage() {

  const [subtitleSwiper, setSubtitleSwiper] = useState(null)
  const { playTime, setPlayTime, syncPlayTime } = usePlayTime(subtitleSwiper)
  const touched = useSelector((state) => state.subtitles.touched)

  const handleIndexChange = useCallback((swiper) => {
    const slideIndex = swiper.activeIndex
    const state = store.getState().subtitles

    const subtitleIndex = slideIndex - 1
    const subtitle = state.subtitleData[subtitleIndex]
    const currentPlayTime = subtitle ? subtitle.startTime : 0
    const visible = subtitle && subtitle.endTime >= currentPlayTime

    setPlayTime(currentPlayTime)
    store.dispatch(setSlideMeta({ slideIndex, subtitleIndex, subtitle, visible }))
  }, [setPlayTime])

  const closeClassName = classNames({invisible: !touched})
  const handleCloseClick = useCallback(() => {
    store.dispatch(exitSubtitlePlayer())
  }, [])

  return (
    <div className="flex flex-col justify-between px-4" style={{ height: '100dvh' }}>

      <CloseButton onClick={handleCloseClick} className={closeClassName} />

      <div className="flex flex-col grow justify-center overflow-hidden">
        <SubtitleSwiper onInstanceChange={setSubtitleSwiper} onIndexChange={handleIndexChange} />
      </div>

        <Controls swiper={subtitleSwiper} playTime={playTime} setPlayTime={setPlayTime} syncPlayTime={syncPlayTime} />
    </div>
  )
}
