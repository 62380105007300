import { memo } from 'react'
import { useSelector } from 'react-redux'
import 'react-range-slider-input/dist/style.css'

import { formatTimestamp } from './tools/format-duration'
import './slider-single.css'

export const ControlsRangeInfo = memo(({ playTime }) => {
  const isScrubbing = useSelector((state) => state.subtitles.scrubbing)
  const scrubbingSpeed = useSelector((state) => state.subtitles.scrubbingSpeed)
  const maxPlayTime = useSelector((state) => state.subtitles.maxPlayTime)

  return (
    <>
      <p id="time" className="">
        {formatTimestamp(playTime)}
      </p>
      {isScrubbing && <p>Playback x{scrubbingSpeed.toFixed(2)}</p>}
      <p id="maxTime" className="">
        {formatTimestamp(maxPlayTime)}
      </p>
    </>
  )
})
ControlsRangeInfo.displayName = 'ControlsRangeInfo'
export default ControlsRangeInfo
